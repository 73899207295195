.itin-data-info {
  height: 150px;
  background-color: #33658a;
  border-radius: 80px 4px 4px 80px;
  display: flex;
  margin: 1rem;
  width: 400px;
}

.itin-data-review-info{
  height: 150px;
  background-color: #33658a;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  width: 400px;
}

.data-box {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  flex-wrap: wrap;
}

.review-user-info {
  display: flex;
}

.review-user-info > div {
  display: flex;
  flex-direction: column;
  padding-top: 2%;
  padding-left: 5%;
}

.review-rating-stars {
  padding-top: 2%;
}

.edit-comment-button {
  color: white
}

.edit-comment-button:hover{
  color: var(--yellow);
  cursor: pointer;
}

.close-review-button{
  color: white;
}

.close-review-button:hover{
  color: var(--yellow);
  cursor: pointer;
}

.data-img {
  height: 100%;
  width: 150px;
  border-radius: 4px;
}
.prof-img {
  height: 100%;
  width: 150px;
  border-radius: 50%;
}

h3 {
  font-family: "Bebas Neue";
  font-size: 30px;
  color: #f6ae2d;
}

h4 {
  font-family: "Bebas Neue";
  font-size: 25px;
  color: #86bbd8;
}

h5 {
  font-family: "Bebas Neue";
  font-size: 15px;
  color: #2f4858;
}

p {
  font-family: "Bebas Neue";
  font-size: 20px;
  color: white;
}

.data-words {
  margin: 10px;

}

.data-review-words{
  margin: 1rem;
  display: flex;
  align-items: center;
  color: white;
}

.middle-route {
  margin: 1rem;
}

.itin-title {
  margin: 1rem;
}
.down-arrow {
  position: relative;
  color: #86bbd8;
  left: 50%;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 5px, 0);
  }
}

.arrow {
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(5deg);
  }
  95% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.flag {
  display: inline-block;
  animation: wiggle 2s infinite;
}

.review-create-button {
  position: relative;
  color: #86bbd8;
  left: 40%;
}

.review-create-button button {
  font-family: "bebas Neue";
  font-size: 25px;
  height: 50px;
  width: 200px;
  border-radius: 4px;
  border: 2px solid var(--orage);
  color: var(--charcoal);
  background-color: var(--orange);
  text-decoration: none;
  transition: all 0.3s;
}
.review-create-button button:hover {
  color: white;
}

.itin-card-title {
  width: 15rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.review-edit-button {
  color: #86bbd8;
}

.review-edit-button button {
  font-family: "bebas Neue";
  font-size: 25px;
  height: 50px;
  width: 100px;
  border-radius: 4px;
  border: 2px solid var(--orage);
  color: var(--charcoal);
  background-color: var(--orange);
  text-decoration: none;
  transition: all 0.3s;
}
.review-edit-button button:hover {
  color: white;
}

.itin-titler{
  display: flex;
  justify-content: space-between;
  align-items: center;
}