.slogan {
  position: absolute;
  top: 4%;
  left: 30%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

h2 {
  font-family: "Bebas Neue";
  font-size: 50px;
  color: #f88243;
}
.route-name {
  padding: 1rem;
}

.login-line {
  width: 100%;
  text-align: center;
  line-height: 1px;
}
.route-line {
  width: 100%;
  margin-right: 1rem;
  margin-left: 1rem;
  text-align: center;
  height: 5px;
  border-radius: 0px 4px 4px 0px;
  /* padding: 1rem; */
}

.line {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 2%;
}

.route-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 2%;
  margin: 1rem 0rem;
}

.route-content {
  width: 90%;
  height: 11rem;
  border-radius: 10px;
  background-color: #33658a;
}

.route-choose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 11rem;
  margin-left: 1rem;
  border-radius: 10px;
  background-color: #33658a;
}

.route-choose button {
  all: unset;
}

.route-content h2 {
  width: 100%;
  color: #f88243;
  font-size: 30px;
}

.route-line-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.route-line-content h2 {
  font-size: 20px;
  color: #f6ae2d;
}
.route-marker {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0rem 9rem;
}

.route-dot {
  position: absolute;
}

.route-info {
  left: 50%;
}
.route-city-name {
  text-align: center;
  padding-top: 2rem;
}

.map-marker-info-inner h2 {
  font-family: "Bebas Neue";
  font-size: 50px;
  color: #f88243;
}

.route-line {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.route-map-marker {
  animation: fadeIn 2s;
}

.splash-image {
  width: 100%;
}

/* --------------------- */

/* body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
} */

.big-image {
  font-family: "Bebas Neue";
  height: 100vh;
  width: 100vw;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url("../../assets/images/splash.jpg");
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  color: #f6ae2d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 2s ease-in-out;
}

.overlay h1 {
  font-size: 65px;
  letter-spacing: 0px;
  margin: 0 0 5px;
}

.overlay p {
  margin: 0;
  font-size: 28px;
}

.hero-button {
  margin-top: 20rem;
}

.hero-button button {
  font-family: "bebas Neue";
  font-size: 25px;
  margin: 3rem 10rem;
  height: 50px;
  width: 200px;
  border-radius: 4px;
  border: 2px solid white;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  transition: all 0.3s;
}
.hero-button button:hover {
  color: var(--orange);
  border: 2px solid var(--orange);
}

.browse-button {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.create-button {
  cursor: pointer;
}

.map-splash {
  animation: fadeIn 1s;
  width: 90%;
}
.map-splash-title {
  animation: fadeIn 1s;
  color: #f6ae2d;
}

.title-route {
  margin: 0;
}

.splash-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin: 3rem;
}

.detail-data {
  display: flex;
  justify-content: space-between;
  height: 450px;
  width: 90%;
  background: linear-gradient(-45deg, #f6ae2d, #f88243);
}

.detail-data h2 {
  font-size: 65px;
  color: var(--charcoal);
}
.detail-data h3 {
  font-size: 50px;
  color: var(--light-blue);
  width: 50%;
}

.detail-image img {
  height: 100%;
  width: 100%;
}

.detail-words {
  margin: 3rem;
}

.create-review-button {
  position: relative;
  color: #86bbd8;
  left: 40%;
}

.create-review-button button {
  font-family: "bebas Neue";
  font-size: 25px;
  height: 50px;
  width: 200px;
  border-radius: 4px;
  border: 2px solid var(--orage);
  color: var(--orange);
  background-color: var(--charcoal);

  text-decoration: none;
  transition: all 0.3s;
}
.create-review-button button:hover {
  color: white;
}



.user-created-title {
  margin: 3rem;
}
.user-created-title h2 {
  color: var(--yellow);
}
