.user-image{
    border-radius: 50%;
    height: 150px;
    width: 150px;
}

.user-data{
    margin: 2rem;
}

.user-data h5{
    margin: 1rem;
}