.nav-div {
  display: flex;
  flex-direction: row;
  position: sticky;
  justify-content: space-between;
  align-items: center;
  /* align-content: center; */
  background-color: #33658a;
  border-bottom: 2px solid var(--charcoal);
  padding: 0.5rem;
}

.title-div {
  background-color: #f88243;
  width: auto;
  height: 5vh;
  display: flex;
  align-items: center;
  align-content: center;
}

.title {
  font-family: "Bebas Neue";
  font-size: xx-large;
  color: #33658a;
}

.navlink-title {
  text-decoration: none;
}

.signup-link {
  margin: 1vw;
  text-decoration: none;
  color: #33658a;
  background-color: #f88243;
  border: none;
  cursor: pointer;
  font-family: "Bebas Neue";
  font-size: large;
}

.login-link {
  margin: 1vw;
  text-decoration: none;
  color: #33658a;
  background-color: #f88243;
  border: none;
  cursor: pointer;
  font-family: "Bebas Neue";
  font-size: large;
}
