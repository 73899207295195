.modal-background {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(25, 25, 25, .5);
    z-index: 3;
}

.modal-foreground {
    max-height: 100%;
    min-height: 50%;
    width: 700px;
    background-color: #33658A;
    z-index: 4;
    position: fixed;
    top:calc(50% - 250px);
    left:calc(50% - 350px);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color:#F88243;
    font-family: "Bebas Neue";
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 30px;
}

.modal-header {
    width: 100%;
    margin: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.modal-header > * {
    align-self: center;
}

.close-button {
    cursor: pointer;
    padding: 0% 5%;
    width: 1vw;
    position: absolute;
    top: 10.5%;
    right: 1%;
}

.modal-header{
    font-size: x-large;
}