

.outer-new-div {
  /* margin: 2rem; */
  display: flex;
  justify-content: center;
}

.all-new-div {
  width: 60%;
  margin-left: 1rem;
}

.yelp-new-div {
  width: 40%;

}

.title-new-input {
  font-family: "bebas neue";
  color: var(--yellow);
  font-size: 40px;
  height: 50px;
  transition: all 0.3s;
  background-color: transparent;
  border: none;
  width: 80%;
  padding: 0px;
}

.title-new-input:focus {
  outline: none;
  border-bottom: 2px solid var(--yellow);
}

.title-new-input-box {
  display: flex;
  align-items: center;
}
.itinerary-new-title {
  margin-bottom: 2rem;
}

.city-new-div-start-end {
  margin: 2rem 0rem;
}

/* .tabs{
display: flex;
align-items: center;
justify-content: space-evenly;
} */

.city-collection {
  /* height: 500px; */
  /* width: 700px; */
  background-color: var(--charcoal);
  border-radius: 4px;
}

/* ------------- */

.tabs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* background: #e5e5e5; */
}

.input {
  position: absolute;
  opacity: 0;
}

.label {
  font-family: "bebas neue";
  width: 100%;
  padding: 20px 30px;
  /* background: #e5e5e5; */
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  color: var(--yellow);
  transition: background 0.1s, color 0.1s;
}
.label:hover {
  /* background: #d8d8d8; */
  color: var(--light-blue);
  border-bottom: 2px solid var(--light-blue);
}

.input:focus + .label {
  z-index: 1;
}
.input:checked + .label {
  background: transparent;
  border-bottom: 2px solid var(--yellow);
  color: var(--yellow);
}

@media (min-width: 600px) {
  .label {
    width: auto;
  }
}

.panel {
  display: none;
  /* padding: 20px 30px 30px; */
  width: 100%;
  background: transparent;
  transition: all 0.5s ease-in-out;
  animation: fadeIn 2s;
}

@media (min-width: 600px) {
  .panel {
    order: 99;
  }
}
.input:checked + .label + .panel {
  display: block;
}

.collection-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

}
.show-itin-info {
  width: 350px;
}

.empty-title{
    margin: 1rem;
}

.show-page-no-user-cta{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.show-review{
    left: 0% !important;
    margin-top: 1rem;
}
.show-review button{
    font-size: 20px !important;
}

.end-city{
    margin: 1rem 0rem;
}

/* ----------yelp----------------- */


.result-div{
    display: flex;
    flex-wrap: wrap;
    justify-content: column;
    align-items: center;
    width: 200px;
    height: 5rem;
}
.result-img-div{
    width: 100%;
    height: 100%;
}


.yelp-data-info{
    width: 100% !important;
    height: 300px !important;
}

.yelp-img {
    width: 300px !important;
    height: 300px !important;
}

.button-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.yelp-container{
    overflow: hidden;
}
.yelp-collection{
    height: 100vh;
    overflow-y: auto;
    float: right;
    position: sticky;
    top: 0;
    border-left: 2px solid var(--yellow);

}

.close-card:hover{
    cursor: pointer;
    color: var(--yellow);

}
.close-card{
    position: relative;
    bottom: 0;
    right: 0; 
    color: white;
    transition: all 0.5s;
}