.update-button-div{
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 5vh;
    background-color: #f88243;
    margin-top: 1%;
    margin-bottom: 5%;
}

.update-button-edit{
    background-color: #f88243;
    font-family: "Bebas Neue";
    color: #33658A;
    cursor: pointer;
    font-size: 30px;
    cursor: pointer;
}

.outer-show-div-edit{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin-top: 3%;
}

.all-cities-div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}

.city-div{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    align-content: flex-start;
    height: 25vh;
    width: 35vw;
    margin-top: 5%;
    margin-bottom: 5%;
}

.city-div-start-end-edit{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    align-content: flex-start;
    height: 5vh;
    width: 35vw;
    margin-top: 1%;
    margin-bottom: 5%;
}

.city-title-div{
    width: 70%;
    height: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.city-title-div-start-end-edit{
    width: 150%;
    height: 150%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(134, 187, 216);
}

.a-h-f-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.a-h-f-words-edit{
    margin-left: 1%;
    margin-right: 1%;
    font-family: "bebas neue";
    padding: 20px 30px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    color: var(--yellow);
    background: none;
    border: none;
}

.city-title{
    color: #F88243;
    font-family: "Bebas Neue";
    font-size: 50px;
    margin-left: 1vw;
}

.yelp-div-edit{
    height: 100vh;
    width: 40vw;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    align-content: center;
    border-left: 1px solid var(--yellow);
    overflow-y: auto;
}

.results-div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 90%;
}

.result-div-edit{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    height: 30%;
    border-radius: 20px;
    margin-top: 10%;
    margin-bottom: 10%;
}

.result-img-div{
    width: 100%;
    height: 100%;
    margin: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.result-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.result-info-div{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}


.rating-div{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.result-rating{
    margin-right: 5%;
}

.result-title{
    font-weight: bold;
}
.button-div{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.itinerary-title{
    display: flex;
    flex-direction: column;
    color: #F88243;
}

.itinerary-title > input {
    margin-top: 2%;
}

.show-page-cta {
    border: 2px solid white;
}

.outer-show-div {
    /* margin: 2rem; */
    display: flex;
    justify-content: center;
  }
  
  .all-cities-div {
    width: 60%;
    margin-left: 1rem;
  }
  
  .yelp-div {
    width: 40%;
  
  }
  
  .title-input {
    font-family: "bebas neue";
    color: var(--yellow);
    font-size: 40px;
    height: 50px;
    transition: all 0.3s;
    background-color: transparent;
    border: none;
    width: 80%;
    padding: 0px;
  }
  
  .title-input:focus {
    outline: none;
    border-bottom: 2px solid var(--yellow);
  }
  
  .title-input-box {
    display: flex;
    align-items: center;
  }
  .itinerary-title {
    margin-bottom: 2rem;
  }
  
  .city-div-start-end-edit {
    margin-top: 10%;

  }
  
  /* .tabs{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  } */
  
  .city-collection {
    /* height: 500px; */
    /* width: 700px; */
    background-color: var(--charcoal);
    border-radius: 4px;
  }
  
  /* ------------- */
  
  .tabs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* background: #e5e5e5; */
  }
  
  .input {
    position: absolute;
    opacity: 0;
  }
  
  .label {
    font-family: "bebas neue";
    width: 100%;
    padding: 20px 30px;
    /* background: #e5e5e5; */
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    color: var(--yellow);
    transition: background 0.1s, color 0.1s;
  }
  .label:hover {
    /* background: #d8d8d8; */
    color: var(--light-blue);
    border-bottom: 2px solid var(--light-blue);
  }
  
  .input:focus + .label {
    z-index: 1;
  }
  .input:checked + .label {
    background: transparent;
    border-bottom: 2px solid var(--yellow);
    color: var(--yellow);
  }
  
  @media (min-width: 600px) {
    .label {
      width: auto;
    }
  }
  
  .panel {
    display: none;
    /* padding: 20px 30px 30px; */
    width: 100%;
    background: transparent;
    transition: all 0.5s ease-in-out;
    animation: fadeIn 2s;
  }
  
  @media (min-width: 600px) {
    .panel {
      order: 99;
    }
  }
  .input:checked + .label + .panel {
    display: block;
  }
  
  .collection-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  
  }
  .show-itin-info {
    width: 350px;
  }
  
  .empty-title{
      margin: 1rem;
  }
  
  .show-page-no-user-cta{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
  }
  
  .show-review{
      left: 0% !important;
      margin-top: 1rem;
  }
  .show-review button{
      font-size: 20px !important;
  }

  .itin-name-edit{
    margin-left: 2%;
    height: 20vh;
    width: 50vw;
    border-bottom: 1px solid rgb(134, 187, 216);
  }
  
  
  /* ----------yelp----------------- */
  
  
  .result-div{
      display: flex;
      flex-wrap: wrap;
      justify-content: column;
      align-items: center;
      width: 200px;
      height: 5rem;
  }
  .result-img-div{
      width: 100%;
      height: 100%;
  }
  
  
  .yelp-data-info{
      width: 100% !important;
      height: 300px !important;
  }
  
  .yelp-img {
      width: 300px !important;
      height: 300px !important;
  }
  
  .button-div{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
  }
  
  .yelp-container{
      overflow: hidden;
  }
  .yelp-collection{
      height: 100vh;
      overflow-y: auto;
      float: right;
      position: sticky;
      top: 0;
      border-left: 2px solid var(--yellow);
  
  }
  
  .close-card:hover{
      cursor: pointer;
      color: var(--yellow);
  
  }
  .close-card{
      position: relative;
      bottom: 0;
      right: 0; 
      color: white;
      transition: all 0.5s;
  }

  .choose-button{
    font-family: "bebas Neue";
    font-size: 25px;
    height: 50px;
    width: 200px;
    border-radius: 4px;
    border: 2px solid var(--orage);
    color: var(--charcoal);
    background-color: var(--orange);
    text-decoration: none;
    transition: all 0.3s;
    cursor: pointer;
  }

  .result-title-edit{
    font-size: xx-large;
  }

  .check-me-out{
    margin-top: 2vh;
    font-family: "bebas Neue";
    font-size: 25px;
    height: 50px;
    width: 200px;
    border-radius: 4px;
    border: 2px solid var(--orange);
    color: var(--charcoal);
    background-color: var(--orange);
    text-decoration: none;
    transition: all 0.3s;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
  }

  .chosenDiv{
    background-color: #33658A;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width: 15vw;
    height: 15vh;
    overflow: hidden;
    border-radius: 10%;
  }

  .chosenPic{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10%;
  }

  .chosenInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    align-content: flex-start;
    margin-left: 0.5vw;
    height: 100%;
    width: 100%;
  }

  .chosenPicDiv{
    width: 9vw;
    height: 10vw;
  }